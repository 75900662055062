.practice-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.details-summary {
  width: 80%;
  margin-bottom: 20px;
}

.details-summary h4,
.details-summary p {
  margin: 10px 0;
}

.details-summary input[type="text"],
.details-summary input[type="date"] {
  margin-left: 10px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.time-interval-table {
  width: 80%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.time-interval-table th,
.time-interval-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.time-interval-table th {
  background-color: #f2f2f2;
}

.time-interval-table td {
  height: 80px;
  vertical-align: middle;
}

.backready{
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}

.back-button,
.ready-button {
  padding: 10px 20px;
  background-color: #226cb1;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin: 10px;
}

.add-drill-icon,
.drill-icon {
  font-size: 1.2em;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  background-color: white;
  padding: 20px;
  border-radius: 4px;
}

.modal label {
  display: block;
  margin-bottom: 5px;
}

.modal input,
.modal textarea {
  width: 100%;
  padding: 8px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.modal button {
  padding: 8px 15px;
  background-color: #226cb1;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
}

.practice-schedule {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.practice-period-capsule {
  background-color: #f0f0f0;
  border-radius: 20px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.practice-period-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.practice-period-time {
  font-weight: bold;
}

.practice-period-name {
  font-style: italic;
}

.practice-period-actions {
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
}

.practice-period-actions button {
  padding: 5px 10px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.practice-period-assignments {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.player-assignment {
  flex: 1 1 calc(33.333% - 10px);
  min-width: 150px;
  padding: 10px;
  border-radius: 10px;
  text-align: center;
}

.player-name {
  font-weight: bold;
  display: block;
  margin-bottom: 5px;
}

.player-activity {
  display: block;
}

.drill-icon {
  margin-top: 5px;
  cursor: pointer;
  font-size: 1.2em;
}

.capsule-container {
  background-color: rgba(255, 255, 255, 0.2);
  border-radius: 15px;
  padding: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
}

.capsule-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.activity-name {
  font-weight: bold;
  margin-bottom: 5px;
}

.capsule-actions {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 100%;
  margin-top: 5px;
}

.action-icon, .view-details-btn {
  cursor: pointer;
  font-size: 1em;
  transition: transform 0.2s;
}

.action-icon:hover {
  transform: scale(1.2);
}




/* view detail specific style */
.scrollable-content {
  max-height: 60vh; /* Allows scrolling for long content */
  overflow-y: auto;
  padding-right: 10px;
}

.details-content {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.detail-card {
  background: #f9f9f9;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.detail-card h4 {
  margin-bottom: 5px;
  font-weight: bold;
}

.detail-card h5 {
  font-weight: bold;
}

.close-btn {
  background: #007bff;
  color: white;
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: block;
  margin: 20px auto 0;
}

.close-btn:hover {
  background: #0056b3;
}


/* Search modal styles */
.search{
  display: flex;
  gap: 10px;
}

.searchBar, .searchButton {
  height: 2.5em;
  border-radius: 25px !important;
}

/* header style */
.headerInfo {
  display: flex;
  gap: 2;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.headerInfo h4 {
  font-size: 1.125rem; 
  font-weight: 600; 
  color: #1f2937; 
}

.dark .headerInfo h4 {
  color: #e5e7eb; 
}

.icon {
  width: 1.25rem;
  height: 1.25rem; 
  transition: all 0.3s ease-in-out;
  color: #9ca3af; 
}

.headerInfo:hover .icon {
  color: var(--primary-color); 
  transform: rotate(90deg);
}
