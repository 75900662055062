/* DrillHub Container */
.drillhub {
  padding: 10px;
  background: linear-gradient(145deg, #ffffff76, #d0d9fca0);
  font-family: Arial, sans-serif;
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Tabs Section */
.tabs {
  display: flex;
  justify-content: center;
  gap: 8px;
}

.tab {
  padding: 6px 10px;
  background: none;
  border: none;
  color: #007bff;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  border-bottom: 2px solid transparent;
  transition: color 0.3s, border-bottom 0.3s;
}

.tab:hover {
  color: #0056b3;
}

.active-tab {
  color: #d9534f;
  border-bottom: 2px solid #d9534f;
}

/* Tab Content Section */
.tab-content {
  background: linear-gradient(145deg, #ffffff76, #d0d9fca0);
  padding: 12px;
  border-radius: 6px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Drill Library */
.drill-library {
  text-align: center;
}

.search-section {
  margin-bottom: 8px;
}

.search-input {
  width: 100%;
  max-width: 300px;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
}

/* Drill Table */
.drill-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  font-size: 12px;
}

.drill-table th,
.drill-table td {
  padding: 5px;
  border: 1px solid #ddd;
}

.drill-table th {
  background-color: #007bff;
  color: white;
  text-transform: uppercase;
  font-size: 12px;
}

.drill-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.drill-table tr:hover {
  background-color: #e7f3ff;
}

/* Add Drill Section */
.add-drill-section {
  max-width: 400px;
  margin: 0 auto;
}

.form-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.form-container label {
  font-size: 12px;
  font-weight: bold;
}

.form-container input,
.form-container textarea {
  width: 100%;
  padding: 6px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 12px;
}

.form-container textarea {
  resize: vertical;
  min-height: 60px;
}

/* File Upload */
.file-label {
  display: inline-block;
  background-color: #007bff;
  color: white;
  padding: 6px 10px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  text-align: center;
  transition: background-color 0.3s;
}

.file-label:hover {
  background-color: #0056b3;
}

.file-upload p {
  font-size: 12px;
  color: #555;
  margin-top: 5px;
}

.file-preview {
  max-width: 80px;
  margin-top: 5px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

/* Add Drill Button */
.add-drill-button {
  padding: 8px;
  background-color: #0046af;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 13px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.add-drill-button:hover {
  background-color: #0076c5;
  transform: scale(1.02);
}

/* Drill Table Buttons */
button {
  cursor: pointer;
  background-color: #c2e3ff;
  color: rgb(0, 0, 0);
  padding: 5px 8px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: #47aaf7;
}

/* Responsive Design */
@media (max-width: 768px) {
  .tabs {
    flex-direction: column;
    gap: 6px;
  }

  .search-input {
    width: 100%;
  }

  .drill-table th,
  .drill-table td {
    font-size: 11px;
    padding: 4px;
  }

  .add-drill-button {
    font-size: 12px;
    padding: 6px;
  }
}

@media (max-width: 480px) {
  .tab {
    font-size: 11px;
    padding: 5px 8px;
  }

  .form-container input,
  .form-container textarea {
    font-size: 11px;
    padding: 5px;
  }

  .add-drill-button {
    font-size: 12px;
    padding: 6px;
  }
}
