/* Sidebar Container */
.sidebar {
  width: 100%; /* Full width for mobile screens */
  max-width: 250px; /* Fixed max width for larger screens */
  height: 100vh; /* Full viewport height */
  background-color: #000e3f; /* Dark blue background */
  color: white;
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000; /* Ensure it's above content */
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.2); /* Add depth */
  overflow: hidden; /* Prevent scrolling */
  padding: 0;
}

/* Logo Section */
.sidebar-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  
  flex-shrink: 0; /* Prevent shrinking */
}

.logo {
  max-width: 100%;
  height: auto; /* Maintain aspect ratio */
}

/* User Info Section */
.sidebar-info {
  width: 100%;
  background: linear-gradient(145deg, #001880, #910500fc);
  border-radius: 1px;
  padding: 10px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  flex-shrink: 0; /* Prevent shrinking */
  margin-bottom: 5px;
}

.profile-picture {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  border: 2px solid rgb(255, 0, 0); /* Red border for contrast */
  object-fit: cover;
}

.info-content h3 {
  font-size: 14px;
  margin-bottom: 5px;
}

.info-content p {
  font-size: 12px;
  margin: 0;
}

/* Calendar Section */
.calendar-section {
  width: 90%;
  text-align: center;
  background: linear-gradient(145deg, #001880, #910500fc);
  padding: 15px;
  border-radius: 1px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  flex-grow: 1; /* Allow this section to grow and fill remaining space */
  overflow: hidden;
}

.calendar-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: #f1f1f1;
}

.nav-button {
  background-color: #1648ad;
  color: white;
  border: none;
  padding: 6px 8px;
  margin-bottom: 5px;
  
  border-radius: 50%;
  cursor: pointer;
  font-size: 12px;
}
.nav-button:hover {
  background: linear-gradient(145deg, #001880, #910500fc);
  transform: scale(1.2);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
}
.calendar-vertical {
  display: flex;
  flex-direction: column;
  gap: 5px;
  overflow-y: auto; /* Allow scrolling only for long content */
  max-height: 80%; /* Prevent overflow */
}

.calendar-day-vertical {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px;
  background-color: #1648ad;
  color: white;
  border-radius: 6px;
  font-size: 13px;
}

.calendar-day-vertical.today {
  background: linear-gradient(145deg, #ff4d4776, #2449f1a0);
  font-weight: bold;
}

/* Footer Section */
.sidebar-footer {
  text-align: center;
  font-size: 12px;
  padding: 5px 0;
  flex-shrink: 0; /* Prevent shrinking */
}

/* Sidebar Responsiveness */
@media (max-width: 768px) {
  .sidebar {
    max-width: 200px;
  }

  .profile-picture {
    width: 50px;
    height: 50px;
  }

  .info-content h3 {
    font-size: 12px;
  }

  .info-content p {
    font-size: 10px;
  }

  .calendar-day-vertical {
    padding: 6px;
    font-size: 12px;
  }
}

@media (max-width: 576px) {
  .sidebar {
    width: 100%; /* Full width for mobile */
    height: auto; /* Adjust height to fit content */
    position: relative; /* Remove fixed position */
    flex-direction: row; /* Change layout for horizontal scrolling */
    justify-content: space-around;
    padding: 10px;
  }

  .sidebar-info {
    flex-direction: row;
    gap: 5px;
  }

  .profile-picture {
    width: 40px;
    height: 40px;
  }

  .info-content h3 {
    font-size: 12px;
  }

  .info-content p {
    font-size: 10px;
  }
}
