.App {
  text-align: center;
}
.app-container {
  display: flex;
  flex-direction: row;
}

.main-content {
  margin-left: 250px; /* Sidebar width */
  padding: 10px;
  width: calc(100% - 250px); /* Full width minus sidebar */
  overflow-y: auto;
  background-color: #f9f9f9;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.content {
  margin-left: 250px; /* Leave space for the sidebar */
  padding: 80px 20px; /* Adjust padding for fixed navigation */
  width: 100%;
  height: 100vh;
  background-color: #f9f9f9; /* Light background for contrast */
  overflow-y: auto; /* Enable scrolling for content */
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
