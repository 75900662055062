/* General Header Styles */
.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 16px;
  background: #ffffff;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  position: sticky;
  top: 0;
  z-index: 1000;
  overflow: visible; /*to show the drop-down */
}

/* Left Navigation (Logo + Links) */
.nav-left {
  display: flex;
  align-items: center;
  gap: 12px;
}

/* Logo Styles */
.logo-container .logo {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease;
}

.logo-container .logo:hover {
  transform: scale(1.1); /* Hover effect for the logo */
}

/* Navigation Links */
.nav-bar {
  display: flex;
  gap: 15px;
}

.nav-link {
  text-decoration: none;
  font-size: 14px;
  color: #00274c;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  transition: background 0.3s ease, transform 0.3s ease, color 0.3s ease;
}

.nav-link:hover {
  background: #0056b3;
  color: white;
  transform: scale(1.05); /* Slight scaling for emphasis */
}

.nav-link.active {
  color: red;
  border-bottom: 2px solid red;
  font-weight: bold;
}

/* Right Navigation (Icons) */
.nav-right {
  display: flex;
  gap: 15px;
}

.nav-icon {
  font-size: 18px;
  color: #0056b3;
  cursor: pointer;
  padding: 8px;
  border-radius: 50%;
  background: rgba(128, 128, 128, 0.2);
  transition: background 0.3s ease, transform 0.3s ease, color 0.3s ease;
}

.nav-icon:hover {
  color: white;
  background: #0056b3;
  transform: scale(1.15);
}

/* Responsive Design */
@media (max-width: 768px) {
  .header {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }

  .nav-bar {
    flex-wrap: wrap;
    gap: 10px;
  }

  .nav-link {
    font-size: 12px;
    padding: 4px 8px;
  }

  .nav-icon {
    font-size: 16px;
    padding: 6px;
  }
}

/* Setting icon dropdown styles */
.settings-dropdown {
  position: relative;
}

.nav-icon-button {
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
}

.dropdown-menu {
  position: fixed;
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
  z-index: 9999;
  min-width: 200px;
  padding: 8px 0;
}

.dropdown-item {
  display: flex;
  align-items: center;
  padding: 10px 16px;
  text-decoration: none;
  color: #333;
  transition: background-color 0.2s;
  width: 100%;
  text-align: left;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
}

.dropdown-item:hover {
  background-color: #f5f5f5;
}

.dropdown-icon {
  margin-right: 0.5rem;
  font-size: 16px;
  color: #64748b;
}

/* Dark theme styles */
.dark-theme {
  background-color: #1a1a1a;
  color: #ffffff;
}

.dark-theme .header {
  background-color: #2c2c2c;
  color: #ffffff;
}

.dark-theme .nav-link {
  color: #ffffff;
}

.dark-theme .nav-link:hover {
  background: #4a4a4a;
}

.dark-theme .nav-link.active {
  color: #ff6b6b;
  border-bottom-color: #ff6b6b;
}

.dark-theme .nav-icon {
  color: #ffffff;
  background: rgba(255, 255, 255, 0.2);
}

.dark-theme .nav-icon:hover {
  background: #4a4a4a;
}

.dark-theme .dropdown-menu {
  background-color: #2c2c2c;
  border-color: #444;
}

.dark-theme .dropdown-item {
  color: #ffffff;
}

.dark-theme .dropdown-item:hover {
  background-color: #3a3a3a;
}

.dark-theme .dropdown-icon {
  color: #a0aec0;
}