Practice Schedule Review Container
.practice-schedule-review {
  padding: 10px;
  background: linear-gradient(145deg, #ffffff76, #aebefda0);
  border-radius: 6px;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.1);
  font-size: 14px;
}

/* Review Summary Section */
.review-summary p {
  margin: 3px 0;
  font-size: 14px;
  color: #333;
}

/* Activities Section */
.activities-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.draggable-activity {
  padding: 8px 10px;
  color: white;
  border-radius: 6px;
  cursor: grab;
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  font-size: 12px;
}

.draggable-activity:hover {
  transform: scale(1.05);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Add Activity Button */
.add-activity-button {
  margin-top: 10px;
  padding: 8px 12px;
  font-size: 12px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.add-activity-button:hover {
  background-color: #0056b3;
  transform: scale(1.03);
}

/* Time Interval Table */
.time-interval-table {
  margin-top: 15px;
}

.time-interval-table h4 {
  margin-bottom: 10px;
  font-size: 16px;
  font-weight: bold;
  color: #0056b3;
  text-align: center;
}

.time-interval-table table {
  width: 100%;
  border-collapse: collapse;
  background: #f9f9f9;
  border-radius: 6px;
  font-size: 12px;
}

.time-interval-table th,
.time-interval-table td {
  border: 1px solid #ddd;
  padding: 5px;
  text-align: center;
}

.time-interval-table th {
  background: linear-gradient(135deg, #007bff, #0056b3);
  color: white;
  font-weight: bold;
  text-transform: uppercase;
}

.time-interval-table td {
  background-color: #fff;
  transition: background-color 0.3s ease;
}

.time-interval-table tr:nth-child(odd) td {
  background-color: #f8faff;
}

.time-interval-table td:hover {
  background-color: #e7f3ff;
}

.time-interval-table table th:first-child,
.time-interval-table table td:first-child {
  width: 60px;
  text-align: center;
}

/* Actions Section */
.actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.back-button {
  background-color: #007bff;
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.back-button:hover {
  background-color: #0056b3;
}

.submit-button {
  background-color: #0084ff;
  width: 20%;
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.submit-button:hover {
  background-color: #0020af;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  padding: 20px;
  border-radius: 10px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
}

.modal h4 {
  margin-bottom: 15px;
  font-size: 18px;
  text-align: center;
}

.modal label {
  display: block;
  margin-bottom: 10px;
  font-size: 14px;
}

.modal input,
.modal select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
}

.radio-group {
  display: flex;
  gap: 10px;
  margin-top: 5px;
}

.radio-label {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
}

.radio-label.selected {
  background-color: #d80000;
  color: white;
}

.checkbox-group {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.checkbox-label-horizontal {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: pointer;
}

.checkbox-label-horizontal.selected {
  background-color: #d80000;
  color: white;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
  margin-top: 15px;
}

.save-button {
  margin-right: 10px; /* space between buttons */
  background-color: #226cb1;
  color: white;
  padding: 8px 12px;
  border-radius: 4px;
  border: none;
  font-size: 14px;
  cursor: pointer;
}

.save-button:hover {
  background-color: #023570;
}


