/* General Layout */
.practice-plan {
  padding: 20px;
  background-color: #f4f4f9;
  border-radius: 10px;
  font-family: "Poppins", Arial, sans-serif;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
}

/* Tabs Section */
.tabs {
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  border-bottom: 2px solid #ddd;
}

.tab {
  padding: 12px 20px;
  font-size: 16px;
  font-weight: 600;
  color: #555;
  border: none;
  background: transparent;
  cursor: pointer;
  transition: color 0.3s ease, border-bottom 0.3s ease;
}

.tab:hover {
  color: #0078d4;
}

.active-tab {
  color: #d93838;
  border-bottom: 3px solid #d93838;
}

/* Tab Content */
.tab-content {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

/* Header Section */
.template-section h3 {
  color: #333;
  font-size: 22px;
  margin-bottom: 20px;
  font-weight: 700;
}

/* Actions Section */
.template-section .actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.create-button {
  padding: 10px 25px;
  background-color: #0078d4;
  color: #fff;
  font-size: 15px;
  font-weight: 600;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.create-button:hover {
  background-color: #005bb5;
  transform: translateY(-2px);
}

.search-container {
  display: flex;
  align-items: center;
  position: relative;
}

.search-container .search-icon {
  position: absolute;
  left: 15px;
  color: #aaa;
  font-size: 16px;
}

.search-container input {
  width: 300px;
  padding: 10px 15px 10px 40px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  color: #333;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.search-container input:focus {
  border-color: #0078d4;
  box-shadow: 0 0 8px rgba(0, 120, 212, 0.3);
  outline: none;
}

/* Schedule Table */
.styled-table {
  width: 100%;
  border-collapse: collapse;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
}

.styled-table th {
  padding: 15px;
  background-color: #0078d4;
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  text-align: center;
}

.styled-table td {
  padding: 15px;
  text-align: center;
  border-bottom: 1px solid #eee;
  font-size: 14px;
  color: #444;
}

.styled-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.styled-table tr:hover {
  background-color: rgba(0, 120, 212, 0.1);
  transition: background-color 0.3s ease;
}

/* Action Buttons */
.actions-cell {
  display: flex;
  justify-content: center;
  gap: 10px;
}

.action-button {
  padding: 10px;
  font-size: 14px;
  border-radius: 8px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.action-button:hover {
  transform: scale(1.1);
}

.view-button {
  background-color: #28a745;
  color: white;
}

.view-button:hover {
  background-color: #218838;
}

.duplicate-button {
  background-color: #ffc107;
  color: white;
}

.duplicate-button:hover {
  background-color: #e0a800;
}

.delete-button {
  background-color: #dc3545;
  color: white;
}

.delete-button:hover {
  background-color: #c82333;
}

/* Modal Overlay */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background: white;
  border-radius: 10px;
  padding: 20px;
  max-width: 500px;
  width: 90%;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  animation: fadeIn 0.3s ease;
}

.modal h3 {
  margin-bottom: 15px;
  color: #333;
  font-size: 18px;
  font-weight: 600;
}

.modal button {
  background-color: #0078d4;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.modal button:hover {
  background-color: #005bb5;
}

/* Responsive Design */
@media (max-width: 768px) {
  .styled-table th,
  .styled-table td {
    font-size: 13px;
    padding: 12px;
  }

  .create-button {
    font-size: 13px;
    padding: 8px 20px;
  }

  .search-container input {
    width: 100%;
  }
}

@media (max-width: 576px) {
  .actions {
    flex-direction: column;
    gap: 15px;
  }

  .create-button {
    width: 100%;
  }

  .search-container input {
    width: 100%;
  }
}

@media print {
  body {
    margin: 0;
    padding: 0;
  }

  .upcoming-practices {
    width: 100%;
    font-size: 12px;
    margin: 0 auto;
  }

  .time-interval-table {
    width: 100%;
    border-collapse: collapse;
  }

  .time-interval-table th,
  .time-interval-table td {
    border: 1px solid black;
    padding: 5px;
    font-size: 10px;
    text-align: center;
  }

  button {
    display: none;
  }
}
