/* Main Container */
.practice-template-form {
  max-width: 700px;
  margin: 20px auto;
  padding: 15px;
  background: linear-gradient(145deg, #ffffff76, #d0d9fca0);
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

/* Heading */
.practice-template-form h3 {
  font-size: 20px;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
}
.form-actions {
  margin-top: 20px; /* Add spacing between the form and button */
  display: flex;
  justify-content: flex-end; /* Align button to the right */
  align-items: center;
}

/* Form Grid */
.form-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 10px;
}

.form-group label {
  font-size: 13px;
  font-weight: bold;
  color: #555;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 8px;
  
  border: 1px solid rgb(169, 185, 255);
  border-radius: 4px;
  font-size: 13px;
  box-sizing: border-box;
}

.form-group textarea {
  resize: none;
  height: 60px;
}

.form-group.full-width {
  grid-column: span 2;
}

/* Player Selection */
.player-selection {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.player-item {
  padding: 5px 10px;
  background: #f4f4f4;
  border: 1px solid #87bfff;
  border-radius: 4px;
  cursor: pointer;
  font-size: 12px;
  transition: background-color 0.3s, color 0.3s;
}

.player-item.selected {
  background: #ca2121;
  color: #ffffff;
}

/* Add Player Section */
.add-player {
  display: flex;
  gap: 5px;
  margin-top: 10px;
}

.add-player input {
  flex: 1;
  padding: 8px;
  border: 1px solid rgb(169, 185, 255);
  border-radius: 4px;
  font-size: 12px;
}

.add-player button {
  padding: 6px 10px;
  background: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-player button:hover {
  background: #0056b3;
}

/* Add Group Button */
.add-group-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}

.add-group-button {
  padding: 6px 12px;
  background: #2f72ca;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-group-button:hover {
  background: #0d229c;
}

/* Groups Container */
.groups-container {
  margin-top: 15px;
  border-top: 1px solid #ddd;
  padding-top: 10px;
}

.group-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #f9f9f9;
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 5px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.group-item span {
  font-size: 13px;
  color: #333;
}

.delete-group-button {
  background: #dc3545;
  color: white;
  border: none;
  padding: 5px 8px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.delete-group-button:hover {
  background: #c82333;
}

/* Submit Button */
.submit-button {
  margin-top: 20px;
  padding: 10px 20px; /* Larger padding for better click target */
  background: linear-gradient(145deg, #007bff, #0056b3); /* Modern gradient */
  color: white;
  border: none;
  border-radius: 8px; /* Slightly more rounded for aesthetics */
  font-size: 14px; /* Modern and readable font size */
  font-weight: 600;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px; /* Space between text and icon */
  transition: transform 0.3s, background-color 0.3s ease-in-out;
  float: right; /* Align button to the right */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15); /* Subtle shadow for depth */
}

.submit-button:hover {
  background: linear-gradient(145deg, #0168d6, #002761); /* Darker hover effect */
  transform: scale(1.05); /* Slight scaling for hover interaction */
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .submit-button {
    float: none; /* Center the button on smaller screens */
    width: 100%; /* Full width for better usability */
    justify-content: center;
  }
}


/* Responsive Design */
@media (max-width: 768px) {
  .form-grid {
    grid-template-columns: 1fr;
  }

  .form-group.full-width {
    grid-column: 1 / -1;
  }

  .player-item {
    font-size: 11px;
    padding: 4px 8px;
  }

  .add-player input {
    font-size: 11px;
  }

  .add-player button {
    font-size: 11px;
  }

  .add-group-button {
    font-size: 11px;
    padding: 5px 10px;
  }

  .submit-button {
    font-size: 12px;
  }
}
/* Tooltip Styling */
.tooltip {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  padding: 10px 15px;
  border-radius: 5px;
  font-size: 14px;
  color: white;
  z-index: 1000;
  animation: fadeInOut 2s ease-in-out;
}

.tooltip.success {
  background-color: #28a745;
}

.tooltip.error {
  background-color: #dc3545;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translate(-50%, -10px);
  }
  10% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -10px);
  }
}



